import React, { useEffect, useState } from 'react';
import SideBar from './SideBar';
import axios from 'axios';
import Navbar from "./Navbar";
import { useAuth } from '../AuthContext';

const Main = () => {
  const { apipath } = useAuth();
  const [userCount, setUserCount] = useState(0);
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await axios.get(`${apipath}/users/details`);
        setUserCount(response.data.userCount);
      } catch (error) {
        console.error('Error fetching user count:', error);
      }
    };

    fetchUserCount();
  }, []);


  return (
    <>

      <SideBar />
      <Navbar />


      <div className="home">
        <div class="toggle-sidebar" style={{ display: "none" }}>
          <i class="bi bi-menu"></i>
        </div>
        <div className="admin-banner">
          <div className="row">
            {showPopup && (
              <div className="col-lg-12 dash-up">
                <div className="dash-strip">
                  <div>
                    <h3>Congrats!! you can now modify your application</h3>
                    <p>
                      Keep all the information up to date with minimal effort
                    </p>
                  </div>
                  <div>
                    <i
                      className="bi bi-x"
                      onClick={() => setShowPopup(false)}
                    ></i>
                  </div>
                </div>
                <div className="over"></div>
              </div>
            )}
            <div className="col-lg-4">
              <div className="admin-dashbox">
                <h2>
                  <i className="bi bi-person"></i> Total Registered Users :
                </h2>
                <ul className="overlapping-avatar">
                  <li><span>{userCount}</span></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="admin-dashbox">
                <h2>
                  <i className="bi bi-bookmark"></i> Completed Orders :{" "}
                </h2>
                <div className="dash-space">
                  <div>
                    <h3 className="pt-2">500</h3>
                    <p>
                      50% <i className="bi bi-arrow-up"></i> from last year
                    </p>
                  </div>
                  <div className="dash-img">
                    <img src="imgs/graph.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4">
              <div className="admin-dashbox">
                <h2>
                  <i className="bi bi-bookmark"></i> Completed Orders :{" "}
                </h2>
                <div className="dash-space">
                  <div>
                    <h3 className="pt-2">500</h3>
                    <p>
                      50% <i className="bi bi-arrow-up"></i> from last year
                    </p>
                  </div>
                  <div className="dash-img">
                    <img src="imgs/graph.png" alt="" />
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-12 p-4">
              <div className="row dash-recent">
                <div className="col-lg-6">
                  <div className="recents">
                    <h5>Recent Activities</h5>
                    <div className="recent-tile">
                      <span>11:25 AM</span>
                      <p>A new User Logged in</p>
                    </div>
                    <div className="recent-tile">
                      <span>11:25 AM</span>
                      <p>A new User Logged in</p>
                    </div>
                    <div className="recent-tile">
                      <span>11:25 AM</span>
                      <p>A new User Logged in</p>
                    </div>
                    <div className="recent-tile">
                      <span>11:25 AM</span>
                      <p>A new User Logged in</p>
                    </div>
                    <div className="recent-tile">
                      <span>11:25 AM</span>
                      <p>A new User Logged in</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sales">
                    <div className="sales-1">
                      <h5>Total Shares Worth</h5>
                      <div className="d-flex">
                        <div>
                          <i className="bi bi-tag"></i>
                        </div>
                        <div>
                          <h6>
                            ₹ 3.6 Cr <span>- 34 %</span>
                          </h6>
                          <p>Compared to 4.8 Cr last year</p>
                        </div>
                      </div>
                    </div>
                    <div className="sales-2">
                      <h5>Repeat Rate</h5>
                      <div className="d-flex">
                        <div>
                          <i className="bi bi-tag"></i>
                        </div>
                        <div>
                          <h6>
                            ₹ 3.6 Cr <span>- 34 %</span>
                          </h6>
                          <p>Compared to 4.8 Cr last year</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Main
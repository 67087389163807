import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const apipath = "https://node.dgtlinnovations.in:4000/api/v1";
  const filepath = "https://node.dgtlinnovations.in:4000/";

  // const apipath = "http://localhost:4000/api/v1";
  // const filepath = "http://localhost:4000/";
  const login = () => {
    setIsLoggedIn(true);
    // Additional login logic (e.g., setting tokens, etc.)
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');
    setIsLoggedIn(false);
    window.location.href = "/";
    // Additional logout logic (e.g., clearing tokens, etc.)
  };
  const adminLogout = () => {
    localStorage.removeItem('admintoken');
    localStorage.removeItem('adminname');
    localStorage.removeItem('adminemail');
    localStorage.removeItem('adminId');
    setIsLoggedIn(false);
    window.location.href = "/";
    // Additional logout logic (e.g., clearing tokens, etc.)
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, apipath, login, logout, adminLogout,filepath}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
import React, { useState, useEffect } from "react";
import axios from "axios";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useAuth } from "../AuthContext";
import iziToast from "izitoast";

const ServiceListAdd = () => {
  const [services, setServices] = useState([]);
  const [serviceName, setServiceName] = useState("");
  const [servicePrice, setServicePrice] = useState("");
  const [serviceLogo, setServiceLogo] = useState(null);
  const [loading, setLoading] = useState(false);

  const { apipath, filepath } = useAuth();

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(`${apipath}/service/list`);
        setServices(response.data.serviceList); // Set the services state with the fetched data
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };
  
    fetchServices();
  }, [services]); // This will re-fetch services whenever the `services` state changes
  
  
  const handleAddService = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const formData = new FormData();
    formData.append("ServiceName", serviceName);
    formData.append("ServicePrice", servicePrice);
    formData.append("ServiceLogo", serviceLogo);
  
    try {
      const response = await axios.post(`${apipath}/service/add`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      const serviceList = response.data.serviceList;
  
      if (Array.isArray(serviceList)) {
        setServices(serviceList); // Update state with the new list of services
      }
  
      // Clear form fields
      setServiceName("");
      setServicePrice("");
      setServiceLogo(null);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        // If the error is due to the unique constraint
        iziToast.error({
          title: "Error",
          message: "Service name already exists. Please add a different name.",
          position: "topCenter",
        });
      } else {
        console.error("Error adding service:", error);
        iziToast.error({
          title: "Error",
          message: "An error occurred while adding the service.",
          position: "topCenter",
        });
      }
    } finally {
      setLoading(false);
    }
  };  
  
  const handleFileChange = (e) => {
    setServiceLogo(e.target.files[0]);
  };

  const handleDeleteService = async (id) => {
    try {
      const response = await axios.delete(`${apipath}/service/delete/${id}`);
      
      if (response.data.success) {
        // Remove the deleted service from the list
        setServices(services.filter(service => service._id !== id));
        iziToast.success({
            message: "Service deleted successfully.",
            position: "topCenter"
          });
      }
    } catch (error) {
      console.error("Error deleting service:", error);
      iziToast.error({
        message: "Error deleting service.",
        position: "topCenter"
      });
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row">
            <h5 className="col-12">Add Service</h5>
            <div className="col-lg-6 mb-3">
              <form onSubmit={handleAddService}>
                <div className="mb-3">
                  <label htmlFor="service-name" className="form-label">
                    Service Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="service-name"
                    value={serviceName}
                    onChange={(e) => setServiceName(e.target.value)}
                    placeholder="Service Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="service-price" className="form-label">
                    Service Price
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="service-price"
                    value={servicePrice}
                    onChange={(e) => setServicePrice(e.target.value)}
                    placeholder="Service Price"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="service-logo" className="form-label">
                    Service Logo
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="service-logo"
                    accept="image/*"
                    onChange={handleFileChange}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-success" disabled={loading}>
                  {loading ? "Adding..." : "Add Service"}
                </button>
              </form>
            </div>

            <div className="col-lg-6">
              <h5>Added Services</h5>
              <ul className="list-group">
                {services.map((service, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                    <div>
                      <strong>{service.ServiceName}</strong> - ${service.ServicePrice}
                    </div>
                    {service.ServiceLogo && (
                      <img
                        src={`${filepath}/ServiceLogo/${service.ServiceLogo}`} // Adjust based on your backend's file serving path
                        alt={service.ServiceName}
                        style={{ width: "100px", height: "100px", objectFit: "cover" }}
                      />
                    )}
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDeleteService(service._id)}
                    >
                      Delete
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceListAdd;

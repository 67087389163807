import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../AuthContext";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { apipath } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    userType: "user",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${apipath}/users/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData)
        }
      );

      const result = await response.json();

      if (result.success) {
        if (result.user.role === 'admin') {
          iziToast.success({
            message: "Login successful",
            position: "topCenter"
          });
          localStorage.setItem('admintoken', result.token);
          localStorage.setItem('adminname', result.user.username);
          localStorage.setItem('adminemail', result.user.email);
          localStorage.setItem('adminId', result.user._id);
          localStorage.setItem('adminrole', result.user.role);
          navigate("/admindashboard");
        } else {
          iziToast.error({
            message: "Access denied: You are not authorized to access this area",
            position: "topCenter"
          });
        }
      } else {
        iziToast.error({
          message: `Login failed: ${result.error}`,
          position: "topCenter"
        });
        console.error("Login failed:", result.error);
      }
    } catch (error) {
      iziToast.error({
        message: "Error during login",
        position: "topCenter"
      });
      console.error("Error during login:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <>
      <div className="container-fluid login">
        <div className="row login-row">
          <div className="col-lg-6">
            <div className="img-login">
              <img src="imgs/Mobile login-rafiki.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6 login-right">
            <h1>Login</h1>
            <p>Welcome !</p>
            <form onSubmit={handleSubmit}>
              <div className="login-item col-lg-8 mx-auto">
                <label htmlFor="name">Username</label>
                <input type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required />
              </div>
              <div className="login-item col-lg-8 mx-auto">
                <label htmlFor="name">Password</label>
                <div className="input-group login-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter your password"
                    aria-label="password"
                    aria-describedby="basic-addon2"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <div className="input-group-append login-append">
                    <span className="input-group-text" id="basic-addon2">
                      <i className={showPassword ? "bi bi-eye-slash" : "bi bi-eye"} onClick={handleVisibility}></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="login-button col-lg-8 mx-auto">
                <button>Login</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login;